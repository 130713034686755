const ArticleIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-article"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M3 4m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z"></path>
            <path d="M7 8h10"></path>
            <path d="M7 12h10"></path>
            <path d="M7 16h10"></path>
        </svg>
    );
};

const ChatIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-message-circle-2"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M3 20l1.3 -3.9a9 8 0 1 1 3.4 2.9l-4.7 1"></path>
        </svg>
    );
};

const ContentIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-book-upload" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <path d="M14 20h-8a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12v5"></path>
        <path d="M11 16h-5a2 2 0 0 0 -2 2"></path>
        <path d="M15 16l3 -3l3 3"></path>
        <path d="M18 13v9"></path>
        </svg>
    );
};




const HomeIcon = ()=> {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-home-2" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M5 12l-2 0l9 -9l9 9l-2 0"></path>
            <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7"></path>
            <path d="M10 12h4v4h-4z"></path>
        </svg>
    )
}

const LinksIcon = ()=> {
    return (
        <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-link"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M9 15l6 -6" /><path d="M11 6l.463 -.536a5 5 0 0 1 7.071 7.072l-.534 .464" /><path d="M13 18l-.397 .534a5.068 5.068 0 0 1 -7.127 0a4.972 4.972 0 0 1 0 -7.071l.524 -.463" /></svg>
    )
}

const icons = { ArticleIcon, ChatIcon, HomeIcon, ContentIcon, LinksIcon };

const resources = {
    id: 'Reources',
    title: 'Resources',
    caption: 'Research tools',
    type: 'group',
    children: [
        {
            id: 'Home',
            title: 'Home',
            type: 'item',
            url: 'https://www.planetary.blue/',
            icon: icons.HomeIcon,
            breadcrumbs: false
        },
        {
            id: 'resourse',
            title: 'Resource Aggregation',
            type: 'item',
            url: '/resource-aggregation',
            icon: icons.ArticleIcon,
            breadcrumbs: false
        },
        {
            id: 'content',
            title: 'Add new Resource',
            type: 'item',
            url: '/content-management',
            icon: icons.ContentIcon,
            breadcrumbs: false
        },
        {
            id: 'chat',
            title: 'Conversations',
            type: 'item',
            url: 'https://earpi-ai.vercel.app/',
            icon: icons.ChatIcon,
            breadcrumbs: false
        },
        {
            id: 'Links',
            title: 'links',
            type: 'item',
            url: 'https://links.planetary.blue/',
            icon: icons.LinksIcon,
            breadcrumbs: false
        }
    ]
};

export default resources;
